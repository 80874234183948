<template>
  <div>
    <el-form label-position="right"  ref="form" status-icon :model="form" :rules="rules" label-width="200px" style="width:95%;overflow:auto">
      <el-form-item label="卡号" prop="id">
        <el-input v-model="form.id" autocomplete="off" placeholder="请输入卡号" :disabled="row ? true : false"></el-input>
      </el-form-item>
      <el-form-item label="卡用户名称" prop="name">
        <el-input v-model="form.name"  placeholder="请输入卡用户名称"></el-input>
      </el-form-item>
      <el-form-item label="卡用户电话" prop="phone">
        <el-input v-model="form.phone"  placeholder="请输入卡用户电话"></el-input>
      </el-form-item>
      <!-- <el-form-item label="卡类型" prop="cardType">
        <el-select class='select each' v-model="form.cardType" clearable placeholder="请选择卡类型" :disabled="userInfo.roleLevel < 10 ? false : true">
          <el-option
           v-for="item in cardTypeOptions"
           :key="item.id"
           :label="item.name"
           :value="item.id">
          </el-option>
        </el-select>                                                                  
      </el-form-item> -->
      <el-form-item label="电瓶车桩总金额折扣（%）" prop="bikeDiscount">
        <el-input v-model="form.bikeDiscount" placeholder="请输入专用卡电瓶车桩折扣(范围：0 ~ 100,100即表示不打折)" type="number"></el-input>
      </el-form-item>
      <el-form-item label="新能源桩服务费折扣（%）" prop="carDiscount">
        <el-input v-model="form.carDiscount" placeholder="请输入专用卡新能源车桩折扣(范围：0 ~ 100,100即表示不打折)" type="number"></el-input>
      </el-form-item>
      <el-form-item label="所属商户" prop="merchantId" v-if="userInfo.roleLevel < 5 && ( (row && !row.merchantId) || !row)">
        <el-select v-model="form.merchantId" placeholder="请选择所属商户" @change="chooseMerchant">
          <el-option
            v-for="item in managerUnitOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="可用小区" prop="unitId">
        <el-select class='select each' v-model="form.unitId" clearable placeholder="请选择可用小区">
          <el-option
           v-for="item in unitOptions"
           :key="item.id"
           :label="item.name"
           :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain='true'>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getNeighbourhoodList,getMerchantList,createCardUser,updateCardUser } from '@/api/public.js';
import { discount } from '@/api/publicFun.js'
export default {
  props:['row'],
  data() {
    return {
        form:{ // 表单信息
          cardType:1,
          id:null,
          name:null,
          phone:null,
          bikeDiscount:null,
          carDiscount:null,
          merchantId:null,
          unitId:null,
        },
        cardTypeOptions:[ // 卡类型选项
          {id:1,name:'专用卡'},
          {id:2,name:'通用卡'},
        ],
        unitOptions:[], // 所属小区
        managerUnitOptions:[],
        rules:{ // 表单验证
            id: [
                { required: true, message: '卡号不得为空', trigger: 'blur' }
            ],
            name:[
                { required: true, message: '卡用户名称不得为空', trigger: 'blur' }
            ],
            phone:[
                { required: true, message: '卡用户电话不得为空', trigger: 'blur' }
            ],
            cardType: [
                { required: true, message: '请选择卡类型', trigger: 'blur' }
            ],
            merchantId:[
                { required: true, message: '请选择所属商户', trigger: 'change' }
            ],
            unitId: [
                { required: true, message: '请选择可用小区', trigger: 'blur' },
            ],
            bikeDiscount:[
                { required: true, message: '电动车桩折扣不得为空', trigger: 'blur' },
                {required:true,validator:discount,trigger:'blur'}
            ],
            carDiscount:[
                { required: true, message: '新能源车桩折扣不得为空', trigger: 'blur' },
                {required:true,validator:discount,trigger:'blur'}
            ]
        },
        userInfo:JSON.parse(localStorage.getItem('userInfo')),
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.form = this.row ? {...this.row} : this.form;
    this.getMerchantOptions();
    this.getNieghbourList(null);
    this.form.cardType = this.row ? this.form.cardType : 1;
    this.form = {...this.form};
   
  },

  methods: {
    // 获取小区列表
    getNieghbourList(e){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:e,
          merchantId:this.form.merchantId,
        }
      }
      getNeighbourhoodList(data).then(res=>{
        if(res.success){
          this.unitOptions = res.data.list;
        }
      })
    },
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.managerUnitOptions = res.data.list;
        }else{
          this.managerUnitOptions = [];
        }
      })
    },
    // 选择商户、小区
    chooseMerchant(e){
      this.form.merchantId = e;
      this.getNieghbourList();
    },
    close(){
        this.$emit('close')
    },
    // 新增
    async add(){
        await createCardUser({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
      },
      // 编辑
      async edit(){
        await updateCardUser({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
      },
      // 提交
      submit(form) {
         this.$refs["form"].validate(valid => {
            if (valid) {
                this.form.discount = Number(this.form.discount)
                this.$confirm('确认提交吗？', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(()=>{
                  if(this.row){
                    this.edit();
                  }else{
                    this.add();
                  }
                })
            }else{
              return false;
            }
        });
      },
  },
};
</script>
<style scoped></style>
