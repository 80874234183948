<!-- 卡用户 -->
<template>
  <div class="outContainer">
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-input class="each" placeholder="卡号" v-model="searchInfo.param.cardUserId">
      </el-input>
      <!-- <el-input class="each" placeholder="卡用户名称" v-model="searchInfo.param.cardUserName">
      </el-input>
      <el-input class="each" placeholder="卡用户电话" v-model="searchInfo.param.cardUserPhone">
      </el-input> -->
      <!-- <el-select class='each' placeholder="卡类型" v-model="searchInfo.param.cardType" clearable>
        <el-option
          v-for="item in cardTypeOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select> -->
      <el-cascader class="each" placeholder="所属商户 / 所属小区" v-model="unitId" clearable
        :options="managerUnitOptions"
        :props="props"
        @change="chooseMerchant"
        v-if="userInfo.roleLevel < 5"
      ></el-cascader>
      <el-select class='each' placeholder="所属小区" v-model="searchInfo.param.unitId" clearable v-if="userInfo.roleLevel >= 5 && userInfo.roleLevel < 10">
        <el-option
          v-for="item in unitOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button size="mini" type="primary" icon="el-icon-search" @click="hanldleSearch('search')">搜索</el-button>
      <el-button size="mini" type="primary" icon="el-icon-close" @click="hanldleSearch('clear')">清空搜索条件</el-button>
      <el-button size="mini" @click="openDrawer(null)">新增</el-button>
    </div>
    <!-- 表格 -->
    <div class="tableContainer">
      <el-table :data="tableData" style="width: 100%" height="100%">
        <af-table-column align="center" label="卡号" prop="id">
        </af-table-column>
        <af-table-column align="center" label="卡用户信息" prop="name">
          <template slot-scope="scope">
            <p>{{ scope.row.name ? scope.row.name : '--' }}</p>
            <p>{{ scope.row.phone ? scope.row.phone : '--' }}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="关联微信用户信息" prop="ebikeUserName">
          <template slot-scope="scope">
            <p>{{ scope.row.ebikeUserName ? scope.row.ebikeUserName : '--' }}</p>
            <p>{{ scope.row.ebikeUserPhone ? scope.row.ebikeUserPhone : '--' }}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="可用小区" prop="unitName">
          <template slot-scope="scope">
            <p>{{ scope.row.unitName ? scope.row.unitName : '同商户所有小区通用' }}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="余额(元)" prop="balance">
          <template slot-scope="scope">
            <p>{{ scope.row.balance ? scope.row.balance : 0 }}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="卡类型" prop="cardTypeName">
        </af-table-column>
        <af-table-column align="center" label="折扣(%)" prop="discount" width="180">
          <template slot-scope="scope">
            <p v-if="scope.row.cardType == 1">电瓶车总金额折扣：{{ scope.row.bikeDiscount ? scope.row.bikeDiscount : 0 }}</p>
            <p v-if="scope.row.cardType == 1">新能源服务费折扣：{{ scope.row.carDiscount ? scope.row.carDiscount : 0 }}</p>
            <p v-else>--</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="创建时间" prop="createTimeStr">
        </af-table-column>
        <el-table-column align='center' label="操作" fixed="right" width="300">
          <template slot-scope="scope">
            <el-button size="mini" @click="topUp(scope.row)">充值</el-button>
            <!-- <el-button size="mini" @click="handleTopUpRecords(scope.row)">充值记录</el-button> -->
            <el-popover
              placement="top"
              trigger="click"
              width="auto"
            >
                <div style="text-align: right; margin: 0;display: flex;justify-content: space-between;flex-wrap: wrap;">
                  <span class="spanBtn" @click="openDrawer(scope.row)">编辑</span>
                  <span class="spanBtn" @click="handleDel(scope.row)">删除</span>
                  <span class="spanBtn" @click="handleBind(scope.row)">绑定微信</span>
                </div>
                <el-button style="margin-left: 10px;" slot="reference" size="mini">更多</el-button>
            </el-popover>
            <!-- <el-button size="mini" @click="handleBind(scope.row)">绑定微信</el-button>
            <el-button size="mini" @click="openDrawer(scope.row)">编辑</el-button>
            <el-button size="mini" @click="handleDel(scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <pagination v-bind:child-msg="searchInfo" @callFather="callFather"></pagination>
    <!-- 新增、编辑 -->
    <el-drawer
      :title="row ? '编辑' : '新增'"
      :visible.sync="addDrawer"
      size="50%">
      <add v-if="addDrawer" @close="handleClose"  ref='newForm' :row="row"></add>
    </el-drawer>
    <!-- 绑定微信用户 -->
    <el-drawer
      title="绑定微信用户"
      :visible.sync="bindDrawer"
      size="50%">
      <bind v-if="bindDrawer" @close="handleClose"  ref='newForm' :row="row"></bind>
    </el-drawer>
    <!-- 充值弹框 -->
    <el-dialog title="充值" :visible.sync="dialogFormVisible">
      <el-form :model="topUpForm" label-width="150px">
        <el-form-item label="充值卡号" >
          <p>{{ topUpForm.id }}</p>
        </el-form-item>
        <el-form-item label="充值金额（元）">
          <el-radio-group v-model="topUpForm.amount">
            <el-radio-button :label="30"></el-radio-button>
            <el-radio-button :label="50"></el-radio-button>
            <el-radio-button :label="100"></el-radio-button>
            <el-radio-button :label="150"></el-radio-button>
            <el-radio-button :label="500"></el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmTopup">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 充值记录弹框 -->
    <el-dialog :title="topUpSearchInfo.param.cardUserId" :visible.sync="topUpRecordsDialog">
      <div class="outContainer">
        <div class="search">
        <el-date-picker start-placeholder="充值时间" range-separator="至" end-placeholder="充值时间" v-model="orderTime"
          type="daterange"
          @change='chooseTime'
          :default-time="['00:00:00','23:59:59']"
          class='timeSelect each'>
          </el-date-picker>
          <el-button size="mini" @click="handleEduce">导出</el-button>
        </div>
        <div class="tableContainer">
          <el-table
            :data="topUpRecords"
            :header-cell-style="{ background:'#ddd' }"
            border
            height="calc(100% - 50px)">
              <el-table-column align="center" label="充值金额（元）" prop="amount"></el-table-column>
              <el-table-column align="center" label="充值方式" prop="payTypeName"></el-table-column>
              <el-table-column align="center" label="充值状态" prop="payStatusName"></el-table-column>
              <el-table-column align="center" label="充值时间" prop="createTimeStr"></el-table-column>
          </el-table>
        </div>
        <el-col :span="24" class="toolbar pagination">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            :page-size="10"
            :total="topUpSearchInfo.total"
            style="float:right;"
          ></el-pagination>
        </el-col>
        <!-- <pagination v-bind:child-msg="topUpSearchInfo" @callFather="topUpRecordsCallFather"></pagination> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/Pagination.vue';
import add from './add.vue';
import bind from './bindWeChatUser.vue'
import { getCardUserList,getNeighbourhoodList,getMerchantList,topupCard,deleteCardUser,getTopUpRecords,topUpRecordsEduce } from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
const XLSX = require("xlsx-js-style");
export default {
  data() {
    return {
      searchInfo:{ // 筛选条件
        pageNo:1,
        pageSize:10,
        total:0,
        param:{
          cardType: "",
          cardUserId: "",
          merchantId: "",
          unitId: ""
        },
      },
      tableData: [], // 表格数据
      cardTypeOptions:[ // 卡类型选项
        {id:1,name:'专用卡'}, // 只能用一个小区
        {id:2,name:'通用卡'}, // 同商户所有小区可用
      ],
      userInfo:JSON.parse(localStorage.getItem('userInfo')),
      row:null,
      addDrawer:false,
      bindDrawer:false,
      topUpForm:{ // 充值表单
        id:null,
        amount:0,
      },
      topUpSearchInfo:{ // 充值记录筛选条件
        pageNo:1,
        pageSize:10,
        param:{
          cardUserId:'',
          startTime:null,
          endTime:null,
        },
        total:0,
      },
      topUpRecords:[], // 充值记录列表
      dialogFormVisible:false,
      topUpRecordsDialog:false,
      managerUnitOptions:[], // 所属商户、所属小区选项
      unitId:[],
      props: { // 所属商户、小区 联动选择框配置
        value:'id',
        label:'name',
        lazy: true,
        checkStrictly:true,
        lazyLoad (node, resolve) {
          const { level } = node;
          setTimeout(() => {
            let data = {
              pageNo:1,
              pageSize:1000,
              param:{
                name:null,
                merchantId:node.value,
              }
            }
            if(level == 1){
                getNeighbourhoodList(data).then(res=>{
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  let nodes = [];
                  res.data.list.forEach(element=>{
                      nodes.push({
                        id: element.id,
                        name: element.name,
                        leaf: level >= 1,
                        children:null,
                      })
                  })
                  // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  resolve(nodes);
                })
            }
          }, 1000);
        }
      },
      unitOptions:[], // 所属小区选项
      orderTime:[], // 充值记录时间筛选组件绑定值
    }
  },

  components: {
    pagination,
    add,
    bind
  },

  computed: {},

  mounted() {
    this.init();
    this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : this.getNieghbourList();
  },

  methods: {
    // 筛选事件
    hanldleSearch(type){
      this.searchInfo.pageNo = 1;
      if(type == 'clear'){
        this.searchInfo.param = {};
        this.unitId = [];
      }else{
        this.searchInfo.param.name = this.searchInfo.param.name == "" ? null : this.searchInfo.param.name;
        this.searchInfo.param.phone = this.searchInfo.param.phone == "" ? null : this.searchInfo.param.phone;
      }
      this.init();
    },
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.managerUnitOptions = res.data.list;
          this.managerUnitOptions.forEach(element=>{
            element.children = [];
          })
        }else{
          this.managerUnitOptions = [];
        }
      })
    },
    // 选择商户、小区
    chooseMerchant(e){
      this.searchInfo.param.merchantId = e[0] ? e[0] : null;
      this.searchInfo.param.unitId = e[1] ? e[1] : null;
    },
    // 获取小区列表
    getNieghbourList(merchantId){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:null,
          merchantId:merchantId
        }
      }
      getNeighbourhoodList(data).then(res=>{
        if(res.success){
          this.unitOptions = res.data.list;
        }
      })
    },
    // 初始化获取表格数据
    async init(){
      await getCardUserList(this.searchInfo).then(res=>{
        if(res.success){
          this.tableData = res.data.list;
          this.searchInfo.total = res.data.total;
          this.tableData.forEach(element => {
            element.createTimeStr = timeChange(element.createTime,'seconds');
          });
        }else{
          this.tableData = [];
          this.searchInfo.total = 0;
        }
      })
    },
    // 打开充值弹框
    topUp(row){
      this.topUpForm = {...row};
      this.dialogFormVisible = true;
    },
    // 确认充值
    confirmTopup(){
      this.$confirm('确认充值该金额吗？').then(async ()=>{
        let data = {
          param:{
            cardUserId:this.topUpForm.id,
            amount:Number(this.topUpForm.amount)
          }
        }
        await topupCard(data).then(res=>{
          this.$message({
            message:res.success ? '充值成功' : res.msg,
            type:res.success ? 'success' : 'fail'
          })
          this.init();
          this.dialogFormVisible = false;
        })
      })
    },
    // 查看充值记录
    handleTopUpRecords(row){
      this.topUpRecords = [];
      this.topUpSearchInfo = {
        pageNo:1,
        pageSize:10,
        param:{
          cardUserId:row.id,
          startTime:null,
          endTime:null,
        },
        total:0,
      };
      this.orderTime = [];
      getTopUpRecords(this.topUpSearchInfo).then(res=>{
        if(res.success){
          this.topUpRecords = [...res.data.list];
          this.topUpSearchInfo.total = res.data.total;
          console.log(res.data.total,this.topUpSearchInfo.total,888)
          this.topUpRecords.forEach(element => {
            element.createTimeStr = timeChange(element.createTime,'seconds');
          })
        }else{
          this.topUpRecords = [];
          this.topUpSearchInfo.total = 0;
        }
      })
      this.topUpRecordsDialog = true;
    },
    // 充值记录选择时间
    chooseTime(e){
      this.topUpSearchInfo.param.startTime = e && e[0] ? e[0] : null;
      this.topUpSearchInfo.param.endTime = e && e[1] ? e[1] : null;
      this.topUpSearchInfo.pageNo = 1;
      // 时间段筛选发生变化后，自动更新列表数据
      getTopUpRecords(this.topUpSearchInfo).then(res=>{
        if(res.success){
          this.topUpRecords = [...res.data.list];
          this.topUpSearchInfo.total = res.data.total;
          this.topUpRecords.forEach(element => {
            element.createTimeStr = timeChange(element.createTime,'seconds');
          })
        }else{
          this.topUpRecords = [];
          this.topUpSearchInfo.total = 0;
        }
      })
    },
    // 导出
    handleEduce(){
      this.$confirm('确认导出吗？').then(async ()=> {
        const loading = this.$loading({
            lock: true,
            text: '导出中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        await topUpRecordsEduce(this.topUpSearchInfo).then(res=>{
          if(res.success){
            if(res.data && res.data.length > 0){
              let datalist = res.data;
              const book = XLSX.utils.book_new();
              let data = [['充值金额（元）','充值方式','充值状态','充值时间']];
              datalist.forEach(element => {
                let each = [];
                each.push(element.amount ? element.amount : 0);
                each.push(element.payTypeName ? element.payTypeName : '--');
                each.push(element.payStatusName ? element.payStatusName : '--');
                let createTimeStr = element.createTime ? timeChange(element.createTime,'seconds') : '--';
                each.push(createTimeStr);
                data.push(each);
              })
              // excel导出
              const sheet = XLSX.utils.aoa_to_sheet(data);
              // 设置列宽为自适应
              sheet['!cols'] = [{ wch: 20 },{ wch: 20 },{ wch: 20 },{ wch: 20 }];
              sheet['!rows'] = []; // 行高
              // 设置单元格样式
              for (let row = 0; row < data.length; row++) {
                for (let col = 0; col < data[row].length; col++) {
                  sheet['!rows'].push({ hpx: 28 }) // 行高
                  const cellRef = XLSX.utils.encode_cell({ c: col, r: row });
                  console.log('单元格',cellRef)
                  sheet[cellRef].s = {
                    alignment: {
                      horizontal: 'center', // 水平居中
                      vertical: 'center',   // 垂直居中
                    },
                    font: {
                      bold: true // 加粗
                    }
                  };
                }
              }
              XLSX.utils.book_append_sheet(book,sheet,"sheet1"); // 生成sheet
              XLSX.writeFile(book,`${this.topUpSearchInfo.param.cardUserId} - 充值记录.xlsx`); // 导出
            }else{
              this.$message({
                message:'查询不到数据',
                type:'fail',
              })
            }
          }else{
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
        })
        loading.close();
      })
    },
    // 打开新增、编辑弹框
    openDrawer(row){
      this.row = row;
      this.addDrawer = true;
    },
    // 打开绑定弹框
    handleBind(row){
      this.row = row;
      this.bindDrawer = true;
    },
    // 关闭弹框
    handleClose(){
      this.row = null;
      this.addDrawer = false;
      this.bindDrawer = false;
      this.init();
    },
    // 删除
    handleDel(row){
      this.$confirm('删除后不可恢复，确认删除吗？').then(async ()=>{
        await deleteCardUser({param:row.id}).then(res=>{
          this.$message({
            message:res.success ? '删除成功' : res.msg,
            type:res.success ? 'success' : 'fail'
          })
          this.init();
        })
      })
    },
    // 分页插件事件
    callFather(parm) {
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
    handleCurrentChange(val){
      this.topUpSearchInfo.pageNo = val;
      getTopUpRecords(this.topUpSearchInfo).then(res=>{
        if(res.success){
          this.topUpRecords = [...res.data.list];
          this.topUpSearchInfo.total = res.data.total;
          this.topUpRecords.forEach(element => {
            element.createTimeStr = timeChange(element.createTime,'seconds');
          })
        }else{
          this.topUpRecords = [];
          this.topUpSearchInfo.total = 0;
        }
      })
    },
  },
};
</script>
<style scoped lang="scss">
.spanBtn{
  width: 100%;
}
  /deep/ .el-dialog{
    height: 80% !important;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .el-dialog__body{
        flex: 1;
    }
  }
</style>
