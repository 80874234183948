<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
        <el-form-item label="卡号" prop="cardUserId">
          <el-input v-model="form.cardUserId" disabled></el-input>
        </el-form-item>
        <el-form-item label="微信用户" prop="ebikeUserId">
          <el-select placeholder="输入手机号进行查询" class='select' filterable  remote v-model="form.ebikeUserId" clearable
           :remote-method="remoteMethod" 
           @change="chooseUser"
           @clear="clearUser">
            <el-option
              v-for="(item,index) in WeChatUserIdOptions"
              :key="index"
              :label="item.name + ' - '+ item.phone"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="bind('form')" :plain='true'>绑定</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  import { getChargingPileUser,cardUserBindWechat } from '@/api/public.js';
  export default {
    props:['row'],
    data() {
      return {
        form:{ // 表单数据
          cardUserId:'',
        },
        rules: { // 表单验证
            WeChatUserId: [
            { required: true, message: "微信用户不得为空", trigger: "blur" },
          ],
        },
        WeChatUserIdOptions:[], // 微信用户选项
        searchInfo:{ // 查询条件
          pageNo:1,
          pageSize:10,
          param:{
            name:null,
            phone:null,
            merchantId:null,
          }
        }
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.form.cardUserId = this.row.id;
      this.form.ebikeUserId = this.row.ebikeUserId ? this.row.ebikeUserId : null;
      this.init();
    },
  
    methods: {
        // 获取微信用户列表
        init(){
          getChargingPileUser(this.searchInfo).then(res=>{
              this.WeChatUserIdOptions = res.data.list;
          })
        },
        // 动态查询微信用户列表
        remoteMethod(query){
          this.searchInfo.param.phone = query ? query : null;
          this.init();
        },
        // 选择微信用户
        chooseUser(e){
          this.form.ebikeUserId = e;
          this.form = {...this.form};
        },
        // 清空选项
        clearUser(){
          this.searchInfo.param.phone = null;
          this.init();
        },
        // 关闭弹框
        close(){
           this.$emit('close');
        },
        //  添加
        bind(form) {
           this.$refs["form"].validate(valid => {
            if (valid) {
              cardUserBindWechat({param:this.form}).then((res) => {
                  if (res.success) {
                    this.$message({
                      message: "绑定成功",
                      type: "success",
                    });
                     setTimeout(() => {
                       this.$emit('close');
                     }, 1000);
                  }else{
                    this.$message({
                      message: res.msg,
                      type: "fail",
                    });
                  }
                })
                .catch(error => {});
            } else {
              return false;
            }
          });
        },
    }
  };
  </script>
  <style lang="scss" scoped>
   .el-drawer{
     overflow:auto !important;
   }
   .el-select{
    width: 100%;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  